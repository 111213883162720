@if (countryCode) {
  <img class="app-country-flag"
       [class.app-country-flag--small]="small"
       [class.app-country-flag--size-m]="'m'"
       width="100%" height="100%"
       [src]="'/assets/flags/' + countryCode.toLowerCase() + '.svg'" alt="">
} @else {
  <img class="app-country-flag"
       [class.app-country-flag--small]="small"
       [class.app-country-flag--size-m]="'m'"
       width="100%" height="100%"
       src="/assets/flags/world.svg" alt="">
}
