<div class="app-info-badge glob-badge-bg"
     [class.app-info-badge--no-margin]="!rMargin"
     [class.app-info-badge--icon]="icon"
     [class.app-info-badge--big]="big"
     [class.app-info-badge--icon-big]="iconBig"
     [class.app-info-badge--highlight]="highlight"
     [class.app-info-badge--small]="small"
     [class.app-info-badge--silver]="silver"
     [class.app-info-badge--equipadding]="equiPadding"
>

  @if (badgeType === BadgeTypeEnum.money) {
    <app-double-glow [bgColor]="'yellow'" [circle]="true" [size]="'m'">
      <img src="assets/icons/icon-coin.svg" alt="">
    </app-double-glow>
  } @else if (badgeType === BadgeTypeEnum.rankingCountry) {
    <app-country-flag [countryCode]="countryCode" [size]="'m'"></app-country-flag>
  } @else if (badgeType === BadgeTypeEnum.rankingWorld) {
    <app-double-glow [bgColor]="'white'" [circle]="true" [size]="'m'">
      <img src="assets/icons/icon-globe.svg" alt="">
    </app-double-glow>
  } @else if (badgeType === BadgeTypeEnum.skill) {
    <app-double-glow [bgColor]="'turquoise'" [circle]="true" [size]="'m'">
      <img src="assets/icons/music_note.svg" alt="">
    </app-double-glow>
  }
  <ng-content></ng-content>
</div>
