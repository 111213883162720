import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-country-flag',
  standalone: true,
  imports: [],
  templateUrl: './country-flag.component.html',
  styleUrl: './country-flag.component.scss'
})
export class CountryFlagComponent {
  @Input() countryCode: string | null = null;
  @Input() small = false;
  @Input() size: 'sm' | 'm' | 'l' = 'l';
}
