import {Component, Input} from '@angular/core';
import {BadgeTypeEnum} from "../../../models/badge-type.enum";
import {CountryFlagComponent} from "../country-flag/country-flag.component";
import {DoubleGlowComponent} from "../double-glow/double-glow.component";

@Component({
  selector: 'app-info-badge',
  standalone: true,
  imports: [
    CountryFlagComponent,
    DoubleGlowComponent
  ],
  templateUrl: './info-badge.component.html',
  styleUrl: './info-badge.component.scss'
})
export class InfoBadgeComponent {
  @Input() badgeType!: BadgeTypeEnum;
  @Input() countryCode: string | null = null;
  @Input() equiPadding = false;
  @Input() rMargin = false;
  @Input() big = false;
  @Input() icon = false;
  @Input() iconBig = false;
  @Input() highlight = false;
  @Input() small = false;
  @Input() silver = false;
  protected readonly BadgeTypeEnum = BadgeTypeEnum;
}
